// All user types
const UserTypes = {
  ADMIN: {
    value: 'admin',
    text: 'Admin',
  },
  CUSTOMER: {
    value: 'customer',
    text: 'Customer',
  },
};

export default UserTypes;
