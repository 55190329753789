import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import RoutePaths from '../config/Routes';
import Main from '../layout/Main';
import formStyle from '../theme/styles/Forms';

const useStyles = makeStyles(formStyle);

const NotFound = () => {
  const classes = useStyles();
  const pageHeading = '404';
  const pageSubHeading = "The page you're trying to access was not found.";

  return (
    <Main pageHeading={pageHeading} pageSubHeading={pageSubHeading}>
      <>
        <div className={classes.linkContainer}>
          <Link to={RoutePaths.HOME} variant="body2" color="primary">
            Back Home
          </Link>
        </div>
      </>
    </Main>
  );
};

export default NotFound;
