// Application route path information
const RoutePaths = {
  HOME: '/',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  ACCEPT_INVITATION: '/accept-invitation/:code',
};

export default RoutePaths;
