import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import auth from '../config/Firebase';
import RoutePaths from '../config/Routes';
import Login from './Login';
import AcceptInvitation from './AcceptInvitation';
import ForgotPassword from './ForgotPassword';
import AppLoader from '../components/AppLoader';
import NotFound from './NotFound';
import Default from '../theme/Default';
import { checkAuthState } from '../services/Auth';

// Initialize app routes
const App = () => {
  const [appLoaded, setAppLoaded] = useState(false);

  useEffect(() => {
    checkAuthState()
      .then((res) => {
        if (!res.data.customToken) {
          auth.signOut();
        }
        setAppLoaded(true);
      })
      .catch(() => {
        setAppLoaded(true);
      });
  }, []);

  return (
    <ThemeProvider theme={Default}>
      <CssBaseline />

      {!appLoaded && <AppLoader />}

      {appLoaded && (
        <Router>
          <Switch>
            <Route path={RoutePaths.HOME} exact component={Login} />
            <Route path={RoutePaths.SIGNIN} exact component={Login} />
            <Route path={RoutePaths.FORGOT_PASSWORD} exact component={ForgotPassword} />
            <Route path={RoutePaths.ACCEPT_INVITATION} exact component={AcceptInvitation} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      )}
    </ThemeProvider>
  );
};

export default App;
