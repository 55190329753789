import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { validateToken } from '../services/Auth';
import Main from '../layout/Main';
import SignUpForm from '../components/invitation/SignupForm';
import AskPasswordFrom from '../components/invitation/AskPasswordFrom';

const AcceptInvitation = () => {
  const params = useParams();
  const pageHeading = 'Accept Invitation';
  const pageSubHeading = 'Create your new account';

  const [tokenValidation, setTokenValidation] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);
  const [userAction, setUserAction] = useState(0);
  const [email, setEmail] = useState('');

  useEffect(() => {
    setUserAction(0);
    setTokenValidation(true);
    setTokenValid(false);

    validateToken({ token: params.code })
      .then((res) => {
        if (res.success) {
          setUserAction(res.userAction);
          setEmail(res.email);
          setTokenValidation(false);
          setTokenValid(true);
        } else {
          setUserAction(res.userAction);
          setEmail(res.email);
          setTokenValidation(false);
          setTokenValid(false);
        }
      })
      .catch(() => {
        setUserAction(0);
        setTokenValidation(false);
        setTokenValid(false);
      });
  }, []);

  return (
    <div>
      {tokenValidation && (
        <Main>
          <CircularProgress />
        </Main>
      )}
      {!tokenValid && !tokenValidation && (
        <Main pageHeading="Invalid URL or Invitation expired" pageSubHeading="" />
      )}
      {tokenValid && userAction === 1 && (
        <Main
          pageHeading="Accept Invitation"
          pageSubHeading={`Please enter your password for ${email}`}
        >
          <AskPasswordFrom email={email} token={params.code} />
        </Main>
      )}
      {tokenValid && userAction === 2 && (
        <Main pageHeading={pageHeading} pageSubHeading={pageSubHeading}>
          <SignUpForm email={email} token={params.code} />
        </Main>
      )}
    </div>
  );
};

export default AcceptInvitation;
