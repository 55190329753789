import axios from 'axios';
import auth from '../config/Firebase';

// Signin to firebase with email and password
export const signIn = async ({ email, password }) => {
  // Sign in the user with email and password
  const result = await auth.signInWithEmailAndPassword(email, password);
  const { uid } = result.user;

  // Signout the user to create custom signing token
  await auth.signOut();

  const apiEndpoint = process.env.REACT_APP_MA_API;
  const signInApi = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/auth/signin`,
    withCredentials: true,
    data: {
      uid,
    },
  });

  // Signin user with custom token
  await auth.signInWithCustomToken(signInApi.data.customToken);

  return signInApi;
};

// Signup to firebase with email and password
export const signUp = async ({ firstName, lastName, email, password }) => {
  // Create user
  const result = await auth.createUserWithEmailAndPassword(email, password);
  const { uid } = result.user;

  // Update displayname of the user
  const fullName = `${firstName} ${lastName}`;
  await result.user.updateProfile({ displayName: fullName });

  // Signout the user to create custom signing token
  await auth.signOut();

  const apiEndpoint = process.env.REACT_APP_MA_API;
  const signUpApi = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/auth/signup`,
    withCredentials: true,
    data: {
      uid,
      firstName,
      lastName,
      displayName: fullName,
      email,
    },
  });

  // Signin user with custom token
  await auth.signInWithCustomToken(signUpApi.data.customToken);

  return signUpApi;
};

// Check if user is signed in or not
export const checkAuthState = async () => {
  const apiEndpoint = process.env.REACT_APP_MA_API;
  const checkAuthApi = await axios({
    method: 'GET',
    url: `${apiEndpoint}/api/auth/validate-session`,
    withCredentials: true,
  });

  // Signin user with custom token if exists
  if (checkAuthApi.data.customToken) {
    await auth.signInWithCustomToken(checkAuthApi.data.customToken);
  }

  return checkAuthApi;
};

// Send reset password link
export const sendResetPasswordLink = async ({ email }) => {
  auth.sendPasswordResetEmail(email);
};

// Validate invitation token
export const validateToken = async (data) => {
  const apiEndpoint = process.env.REACT_APP_MA_API;
  const checkTokenApi = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/auth/invitation/validate-token`,
    withCredentials: true,
    data,
  });

  return checkTokenApi.data;
};

// Change invitation status
export const invitationAccepted = async (data) => {
  const apiEndpoint = process.env.REACT_APP_MA_API;
  const checkTokenApi = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/auth/invitation/invitation-accepted`,
    withCredentials: true,
    data,
  });

  return checkTokenApi.data;
};
