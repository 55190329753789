import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import SnackbarMessage from '../SnackbarMessage';
import Validations from '../../utils/Validations';
import { invitationAccepted, signUp } from '../../services/Auth';
import formStyle from '../../theme/styles/Forms';
import RoutePaths from '../../config/Routes';

const useStyles = makeStyles(formStyle);

const Register = ({ email, token }) => {
  const classes = useStyles();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    const { firstName, lastName, password } = data;
    setIsSubmitted(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      show: false,
    });

    signUp({ firstName, lastName, email, password })
      .then(async () => {
        await invitationAccepted({ token });
        window.location.href = RoutePaths.HOME;
      })
      .catch((err) => {
        setIsSubmitted(false);
        setSnackbarMeesage({
          show: true,
          type: 'error',
          message: err.message,
        });
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.authForm}>
        <div>
          <div className={classes.authInput50}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="firstName"
              id="firstName"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="First Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="text"
                  autoFocus
                />
              )}
            />
          </div>
          <div className={classes.authInput50}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="lastName"
              id="lastName"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Last Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="text"
                />
              )}
            />
          </div>
        </div>
        <FormControl className={classes.authInput}>
          <Controller
            control={control}
            rules={Validations.REQUIRED_EMAIL}
            name="email"
            id="email"
            autoFocus
            defaultValue={email}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Email Address"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type="email"
                disabled
              />
            )}
          />
        </FormControl>
        <FormControl className={classes.authInput}>
          <Controller
            control={control}
            className={classes.authInput}
            rules={Validations.REQUIRED}
            name="password"
            id="password"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Password"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type="password"
              />
            )}
          />
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.authSubmitBtn}
          disabled={isSubmitted}
        >
          Sign Up
        </Button>
      </form>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

Register.propTypes = {
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default Register;
