import background from '../../assets/images/background-whitegrunge.jpg';

// Style for main layout
const Style = (theme) => ({
  wrapperOut: {
    display: 'flex',
    minHeight: '100vh',
    maxWidth: 'none',
    background: `#fff url(${background}) repeat-x top center`,
  },
  wrapperIn: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 20,
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.3)',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      boxShadow: 'none',
    },
  },
  logo: {
    marginTop: 20,
    marginBottom: 20,
    maxWidth: '60%',
    display: 'block',
  },
  pageHeader: {
    marginTop: 15,
    marginBottom: 15,
    '& > p': {
      fontSize: '.95em',
    },
  },
});

export default Style;
