import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import SnackbarMessage from '../SnackbarMessage';
import Validations from '../../utils/Validations';
import { invitationAccepted, signIn } from '../../services/Auth';
import formStyle from '../../theme/styles/Forms';
import RoutePaths from '../../config/Routes';

const useStyles = makeStyles(formStyle);

const AskPasswordFrom = ({ email, token }) => {
  const classes = useStyles();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    setIsSubmitted(true);
    signIn({ email, password: data.password })
      .then(async () => {
        await invitationAccepted({ token });
        window.location.href = RoutePaths.HOME;
      })
      .catch((err) => {
        setIsSubmitted(false);
        setSnackbarMeesage({
          show: true,
          type: 'error',
          message: err.message,
        });
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.authForm}>
        <Controller
          control={control}
          rules={Validations.REQUIRED}
          name="password"
          id="password"
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Password"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              type="password"
              autoFocus
              fullWidth
            />
          )}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.authSubmitBtn}
          disabled={isSubmitted}
        >
          Submit
        </Button>
      </form>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

AskPasswordFrom.propTypes = {
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default AskPasswordFrom;
