import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import logo from '../assets/images/logo.png';
import mainLayoutStyle from '../theme/styles/MainLayout';

const useStyles = makeStyles(mainLayoutStyle);

// Layout for pages
const Main = ({ pageHeading, pageSubHeading, children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.wrapperOut}>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs" className={classes.wrapperIn}>
          <img src={logo} className={classes.logo} alt={process.env.REACT_APP_NAME} />
          <Divider />

          <Container component="div" className={classes.pageHeader}>
            <Typography component="h1" variant="h5">
              {pageHeading}
            </Typography>
            <Typography component="p">{pageSubHeading}</Typography>
          </Container>

          {children}
        </Container>
      </Grid>
    </Container>
  );
};

Main.propTypes = {
  pageHeading: PropTypes.string.isRequired,
  pageSubHeading: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Main;
