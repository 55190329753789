import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import loaderStyle from '../theme/styles/Loader';

const useStyles = makeStyles(loaderStyle);

// Show loading for whole application
const AppLoader = () => {
  const classes = useStyles();

  return (
    <Container component="main">
      <div className={classes.loader}>
        <CircularProgress color="inherit" />
      </div>
    </Container>
  );
};

export default AppLoader;
