// Style for application whole loader
const Style = () => ({
  loader: {
    display: 'flex',
    height: '95vh',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
});

export default Style;
