import firebase from 'firebase/app';
import 'firebase/auth';
import UserTypes from './UserTypes';

// Firebase configurations
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize firebase app
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Initialize firebase services
const auth = firebaseApp.auth();

// Connect services with emulator
if (window.location.hostname === 'localhost') {
  auth.useEmulator('http://localhost:9098');
}

const ignoreRoutes = ['accept-invitation'];

auth.onAuthStateChanged((user) => {
  if (user && !ignoreRoutes.includes(window.location.pathname.split('/')[1])) {
    auth.currentUser.getIdTokenResult(true).then((idTokenResult) => {
      if (idTokenResult.signInProvider !== 'custom') return;

      if ('role' in idTokenResult.claims) {
        if (idTokenResult.claims.role === UserTypes.ADMIN.value) {
          window.location.href = process.env.REACT_APP_ADMIN_BASE_URL;
        } else {
          window.location.href = process.env.REACT_APP_CLIENT_BASE_URL;
        }
      } else {
        window.location.href = process.env.REACT_APP_CLIENT_BASE_URL;
      }
    });
  }
});

export default auth;
