import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// Show snackbar message
const SnackbarMessage = ({ type, message, delay, show }) => {
  const [open, setOpen] = useState(show);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={delay} onClose={handleClose}>
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );
};

SnackbarMessage.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  delay: PropTypes.number,
  show: PropTypes.bool,
};

SnackbarMessage.defaultProps = {
  delay: 5000,
  show: true,
};

export default SnackbarMessage;
