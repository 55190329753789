import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import RoutePaths from '../config/Routes';
import SnackbarMessage from '../components/SnackbarMessage';
import Validations from '../utils/Validations';
import { sendResetPasswordLink } from '../services/Auth';
import Main from '../layout/Main';
import formStyle from '../theme/styles/Forms';

const useStyles = makeStyles(formStyle);

const ForgotPassword = () => {
  const classes = useStyles();
  const pageHeading = 'Forgot Password';
  const pageSubHeading = 'Request a reset password link';

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, reset } = useForm();

  const onSubmit = (data) => {
    const { email } = data;
    setIsSubmitted(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      show: false,
    });

    sendResetPasswordLink({ email })
      .then(() => {
        setIsSubmitted(false);
        setSnackbarMeesage({
          show: true,
          type: 'success',
          message: 'Reset password link has been sent to your email address.',
        });
        reset({ email: '' });
      })
      .catch((err) => {
        setIsSubmitted(false);
        setSnackbarMeesage({
          show: true,
          type: 'error',
          message: err.message,
        });
      });
  };

  return (
    <Main pageHeading={pageHeading} pageSubHeading={pageSubHeading}>
      <>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.authForm}>
          <FormControl className={classes.authInput}>
            <Controller
              control={control}
              rules={Validations.REQUIRED_EMAIL}
              name="email"
              id="email"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Email Address"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="email"
                  autoFocus
                />
              )}
            />
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.authSubmitBtn}
            disabled={isSubmitted}
            size="large"
          >
            Reset Password
          </Button>
        </form>

        <div className={classes.linkContainer}>
          <Link to={RoutePaths.SIGNIN} variant="body2" color="primary">
            Remember the password? Sign In
          </Link>
        </div>

        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </>
    </Main>
  );
};

export default ForgotPassword;
