import grey from '@material-ui/core/colors/grey';

// Layout for forms
const Style = () => ({
  authForm: {
    marginBottom: 20,
    marginTop: 20,
  },
  authInput: {
    marginBottom: 15,
    width: '100%',
  },
  authInput50: {
    marginBottom: 15,
    width: '50%',
    float: 'left',
    textAlign: 'left',
    '&:last-child > div': {
      width: '100%',
    },
  },
  textInputNameLast: {
    width: '100%',
  },
  authSubmitBtn: {
    marginTop: 10,
    fontWeight: '600',
  },
  linkContainer: {
    marginBottom: 5,
    fontSize: '.95em',
    '& a': {
      textDecoration: 'none',
      color: grey[900],
    },
  },
});

export default Style;
